
import React, { useLayoutEffect, useRef } from "react"
import Helmet from "react-helmet"
import Layout from "../components/main/layout";
import Seo from "../components/main/seo";
// import { StaticQuery, graphql } from 'gatsby';
import { graphql, Link, withPrefix } from 'gatsby';
import { translaterUtil } from "../helpers/translateUtils"
import MicroInteractionsInit from "../helpers/animations/micro-interactions";
import GoFollowInit from "../helpers/animations/go-follow";
import Closing from "../components/shared/closing";
import IncludeGTM from "../helpers/gtm";
import DgoClientDataCookie from "../helpers/dgo-cookie";
import { dateTransform } from "../helpers/dateUtils";
import { useLocation } from "@reach/router";



const BlogPost = ({ pageContext, data }) => {
  IncludeGTM()
  DgoClientDataCookie()
  // ! MICRO INTERACTIONS: Start

  const miRefMap = useRef(new Map());

  useLayoutEffect(() => {

    // Find all DOM nodes with the "my-class" class name
    const miNodes = document.querySelectorAll('.js-transition');

    // Map each node to a new ref object and store it in the refMap
    miNodes.forEach((node) => {
      miRefMap.current.set(node, React.createRef());
    });


    //Blogpost scripts
    const imgs = document.querySelectorAll('.c-blogpost__content .image');
    imgs.forEach(($img) => {
      $img.onclick = () => {
        $img.classList.toggle('zoom');
      }
    })


    const $shareHolder = document.querySelector('.c-bp-shares__holder')
    $shareHolder.onclick = () => {
      $shareHolder.classList.toggle('is-showing');
    }

  }, []);

  MicroInteractionsInit(miRefMap)

  // ! UTILS

  const translation = (e) => translaterUtil(pageContext.lang, pageContext.template)[e]

  const { pathname } = useLocation();

  // ! STRAPI

  const $alternatePages = data.allSitePage.nodes.filter(e => e.pageContext.alternate == pageContext.alternate);

  const $metadata = data.strapiMetadata;
  const $mainNav = data.strapiMainNavigation;
  const $loginNav = data.strapiLoginNavigation;
  const $footerData = data.strapiFooterNavigation;
  const $socialsData = data.strapiFooterNavigation.Socials.socials_list;
  const $closingData = data.strapiClosing;

  /* Single type */

  const $blogPostData = data.strapiBlogPost;

  const $blogPostMetadata = $blogPostData.Metadata

  const $relatedPosts = data.allStrapiBlogPost.nodes;



  return (


    <Layout mainNav={$mainNav} loginNav={$loginNav} footerData={$footerData} lang={pageContext.lang} alternatePages={$alternatePages} socialData={$socialsData}>

      <Seo metadata={$metadata} pageMetadata={$blogPostMetadata} alternatePages={$alternatePages} activeLang={pageContext.lang} isPreview={true} />

      <Helmet>
        <link href={withPrefix('assets/styles/blogpost.inline.min.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('assets/styles/blogpost.min.css')} rel="stylesheet" type="text/css" />
      </Helmet>

      {/* ! BLOGPOST */}
      <div className="c-blogpost o-section--b u-text">
        <picture className="c-blogpost__pic c-blogpost__pic--xs c-pic">
          <img src={$blogPostData.image.url} width={$blogPostData.image.width} height={$blogPostData.image.height} alt={$blogPostData.title} />
        </picture>
        <picture className="c-blogpost__pic c-blogpost__pic--lg c-pic">
          <img src={$blogPostData.image_banner.url} width={$blogPostData.image_banner.width} height={$blogPostData.image_banner.height} alt={$blogPostData.title} />
        </picture>
        <div className="c-blogpost__wrapper o-wrapper">
          <div className="c-blogpost__holder">
            <div className="c-blogpost__top">
              <ol className="c-blogpost__breadcrumb c-bp-breadcrumb">
                <li className="c-bp-breadcrumb__item">
                  <a href={`/${pageContext.lang}/blog`} className="c-bp-breadcrumb__link">Blog </a>
                </li>
                <li className="c-bp-breadcrumb__item">
                  <a href={`/${pageContext.lang}/blog/categories/${$blogPostData.blog_category.slug}`} className="c-bp-breadcrumb__link">{$blogPostData.blog_category.name}</a>
                </li>
              </ol>
              <h1 className="c-blogpost__heading c-heading">
                {$blogPostData.title}
              </h1>

              {/* ! Data: Mobile */}
              <div className="c-blogpost__data c-bp-data">
                <div className="c-bp-data__item c-bp-data__date">
                  <img src="/assets/images/static/blog/post/icon-calendar.svg" width={30} height={30} alt="Calendar icon" />
                  <time>{dateTransform($blogPostData.date, pageContext.lang)} </time>
                </div>
                <div className="c-bp-data__item c-bp-data__reading">
                  <img src="/assets/images/static/blog/post/icon-clock.svg" width={30} height={30} alt="Clock icon" />
                  <span>{translation(1)} {$blogPostData.read_time}</span>
                </div>
              </div> {/* .c-blogpost__data */}
              <p className="c-blogpost__description">
                {$blogPostData.description}
              </p>
            </div> {/* .c-blogpost__top */}

            <main className="c-blogpost__content">

              <div dangerouslySetInnerHTML={{ __html: $blogPostData.content.data.content }}></div>

            </main>

            <a href={`/${pageContext.lang}/blog`} className="c-blogpost__back-link c-news-link u-tap-size">
              <svg className="c-news-link__arrow" fill="none" height="12" viewBox="0 0 14 12" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m.423352 6.61914 12.131148.00001-3.93421 4.25935c-.16698.1771-.16966.4673-.00605.648.16367.1808.43168.1838.59865.0066.00201-.0022.00403-.0043.00605-.0066l4.65686-5.04169c.1654-.17881.1656-.46874.0005-.64778l-4.65736-5.042001c-.16698-.177136-.43498-.174226-.5986.006546-.16134.1782-.16134.463375 0 .641575l3.93416 4.25932h-12.131148c-.233795 0-.42335234.20522-.42335236.45834-.00000002.25311.18955736.45833.42335236.45833z"></path></svg>
              {translation(0)}
            </a>


          </div> {/* .c-blogpost__holder */}


          {/* ! Data: Desktop */}
          <div className="c-blogpost__holder c-blogpost__data c-bp-data">
            <div className="c-bp-data__item c-bp-data__date">
              <img src="/assets/images/static/blog/post/icon-calendar.svg" width={30} height={30} alt="Calendar icon" />
              <time>{dateTransform($blogPostData.date, pageContext.lang)} </time>
            </div>
            <div className="c-bp-data__item c-bp-data__reading">
              <img src="/assets/images/static/blog/post/icon-clock.svg" width={30} height={30} alt="Clock icon" />
              <span>{translation(1)} {$blogPostData.read_time}</span>
            </div>
          </div> {/* .c-blogpost__data */}


        </div> {/* .c-blogpost__wrapper */}


        {/* ! SHARES */}
        <div className="c-blogpost__shares c-bp-shares">
          <div className="c-bp-shares__holder">
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(`https://dlocalgo.com${pathname}`)}`}
              target="_blank"
              rel="noopener"
              title="Share on Facebook"
              className="c-bp-shares__link u-tap-size"
            >
              <svg
                className="c-bp-shares__icon"
                fill="none"
                height={36}
                viewBox="0 0 36 36"
                width={36}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m22.6684 18.5649c.1381-1.1128.2762-2.1973.4212-3.3171-1.0841 0-2.1476 0-3.211 0 0-.8239-.0138-1.6197.0069-2.4155.0069-.2254.0691-.4648.1657-.6691.2141-.4436.6284-.5915 1.0704-.6197.6077-.0282 1.2153-.0211 1.8161-.0352.0829 0 .1589-.0071.2555-.0141 0-.9507 0-1.88034-.0069-2.80995 0-.05634-.1036-.15493-.1588-.15493-.9184-.04226-1.8369-.11268-2.7484-.09156-.8908.02113-1.7126.31692-2.4031.91553-.8977.77471-1.2844 1.80291-1.3466 2.96491-.0483.8803-.0276 1.7606-.0345 2.6479v.2958c-.9461 0-1.8714 0-2.7898 0v3.3241h2.776v8.6411c.6353.1127 1.2844.1831 1.9542.1831.4903 0 .9668-.0422 1.4364-.0986 0-2.9367 0-5.8312 0-8.7397.946-.007 1.8714-.007 2.7967-.007z"
                  fill="#006cfa"
                />
              </svg>
            </a>
            <a
              href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(`https://dlocalgo.com${pathname}`)}`}
              target="_blank"
              rel="noopener"
              title="Share on LinkedIn"
              className="c-bp-shares__link u-tap-size"
            >
              <svg
                className="c-bp-shares__icon"
                fill="none"
                height={36}
                viewBox="0 0 36 36"
                width={36}
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="#006cfa">
                  <path d="m16.6644 14.2025h.1263 3.5444c.0927 0 .1095.0252.1095.1178v1.3134c0 .0421 0 .0842.0084.16.0926-.1263.16-.2189.2273-.3115.4799-.6567 1.1029-1.1282 1.8859-1.3639.5388-.16 1.0945-.2105 1.6586-.1684.564.0421 1.1113.16 1.6248.4125.5725.2779 1.044.6651 1.4144 1.1871.5894.8335.8672 1.7765.9177 2.7783.0421.8672.0253 1.7428.0337 2.6184.0084.7829 0 1.5743 0 2.3573v2.2395c0 .0168 0 .0421-.0084.0673-.0337 0-.059.0085-.0926.0085-1.1787 0-2.3574 0-3.536 0-.16 0-.16 0-.16-.1516 0-.7493.0084-1.4986.0084-2.2563 0-.783-.0084-1.566-.0084-2.3489 0-.522.0084-1.044 0-1.5576-.0168-.4714-.0758-.9345-.2863-1.3639-.3115-.6566-.8334-.9682-1.5406-.9934-.7325-.0253-1.3134.2694-1.7428.8587-.2441.3368-.3957.7072-.4041 1.1282 0 1.145-.0084 2.2816-.0084 3.4266v3.1487c0 .0758-.0084.1179-.1011.1179-1.2039 0-2.4078 0-3.6202 0-.0168 0-.0421 0-.0673-.0084.0168-3.8139.0168-7.6025.0168-11.4163z" />
                  <path d="m10.7628 14.2117h3.7802c0 .0505.0084.0926.0084.1431v11.0964c0 .1599 0 .1599-.1599.1599-1.1619 0-2.3153 0-3.4771 0-.1095 0-.1516-.0252-.1516-.1431.0084-1.5407 0-3.0814 0-4.6305 0-1.406 0-2.8204 0-4.2264 0-.7324 0-1.4649 0-2.1974 0-.0589 0-.1263 0-.202z" />
                  <path d="m14.8038 10.6481c-.0168.7998-.3452 1.3723-.9935 1.7344-.4798.2694-1.0018.3199-1.5322.2441-.4378-.0589-.8335-.2189-1.145-.5388-.5557-.5557-.7072-1.2376-.5052-1.9785.1937-.7072.6904-1.13658 1.3976-1.31338.6904-.1768 1.3639-.11787 1.9701.28625.4293.28625.6735.69879.7745 1.19553.0169.1431.0253.2862.0337.3704z" />
                </g>
              </svg>
            </a>
          </div>{" "}
          {/* .c-bp-shares__holder */}
        </div>{" "}
        {/* .c-bp-shares */}


      </div> {/* .c-blogpost */}

      {$relatedPosts.length > 0 &&
        <section className="c-related o-section">
          <h2 className="c-related__heading c-heading o-wrapper">{translation(2)}</h2>
          <ul className="c-related__list c-blog o-section--t o-wrapper">

            {$relatedPosts.length > 0 && $relatedPosts.map((relatedPost, j) => {

              let postLink = `/${pageContext.lang}/blog/${relatedPost.slug}`;
              let postCategory = `/${pageContext.lang}/blog/categories/${relatedPost.blog_category.slug}`;


              return (

                <li key={j} className="c-blog__item">
                  <article className="c-news">
                    <div className="c-news__holder u-text">
                      <div className="c-news__text">
                        <a href={postCategory} className="c-news__category">{relatedPost.blog_category.name}</a>
                        <span className="c-news__date">
                          <time>{dateTransform(relatedPost.date, pageContext.lang)} </time>
                          • {relatedPost.read_time}
                        </span>
                      </div>
                      <h2 className="c-news__title">
                        <a href={postLink} tabIndex={-1}>
                          {relatedPost.title}
                        </a>
                      </h2>
                      <a href={postLink} className="c-news__link c-news-link u-tap-size">
                        <svg className="c-news-link__arrow" fill="none" height={12} viewBox="0 0 14 12" width={14} xmlns="http://www.w3.org/2000/svg"><path d="m.423352 6.61914 12.131148.00001-3.93421 4.25935c-.16698.1771-.16966.4673-.00605.648.16367.1808.43168.1838.59865.0066.00201-.0022.00403-.0043.00605-.0066l4.65686-5.04169c.1654-.17881.1656-.46874.0005-.64778l-4.65736-5.042001c-.16698-.177136-.43498-.174226-.5986.006546-.16134.1782-.16134.463375 0 .641575l3.93416 4.25932h-12.131148c-.233795 0-.42335234.20522-.42335236.45834-.00000002.25311.18955736.45833.42335236.45833z" /></svg>
                        {translation(3)}
                      </a>
                    </div>
                  </article>
                </li>

              )
            })}


          </ul>
        </section>
      }



      {/* ! CLOSING */}

      <Closing
        heading={$closingData.title}
        text={$closingData.description}
        cta={$closingData.Link}
        ctaUrl={$closingData.Link.url}
        ctaText={$closingData.Link.title}
      />

    </Layout>

  )
}



export const query = graphql`
  query BlogPostPageQuery ($lang: String, $id: String)  {

  strapiMetadata (locale: {eq: $lang}) {
    title
    description
    avatar{
        url
    }
    
  },
  strapiMainNavigation (locale: {eq: $lang}) {
    links {
      title
      url
    }
  },
  strapiLoginNavigation (locale: {eq: $lang}) {
    links {
      url
      title
    }
  },
  strapiFooterNavigation (locale: {eq: $lang}) {
    Socials {
      title
      socials_list{
        slug
        link{
          title
          url
        }
        image{
          url
          width
          height
        }
      }
    }
    Disclaimer {
      links {
        url
        title
      }
    }
    Link_group {
      link {
        title
        url
      }
    }
  },
  strapiClosing (locale: {eq: $lang}) {
    title
    description
    Link{
      title
      url
    }
  },
  allSitePage {
      nodes {
        path
        pageContext
      }
  },
  strapiBlogPost (id: {eq: $id}) {
    title
    slug
    read_time
    image_banner {
      url
      width
      height
    }
    image {
      url
      width
      height
    }
    date
    blog_category {
      slug
      name
    }
    content {
      data {
        content
      }
    }
    Metadata {
      title
      keywords
      description
      avatar{
        url
        width
        height
      }
    }
  },
  allStrapiBlogPost (filter: {locale:{eq: $lang},id:{ne: $id}}) {
    nodes{
      title
    slug
    read_time
    date
    blog_category {
      slug
      name
    }
    }
  }

}
`

export default BlogPost
