import { useLayoutEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import Debounce from '../debounce';


export default function  GoFollowInit($follow, homeHero){

    useLayoutEffect(() => {

        // ! VARIABLES

        function setFollowVariables() {

            let followStartPosition = $follow.el.current.offsetTop
            let followEndPosition = $follow.triggers.end.current.offsetTop - followStartPosition
            
            $follow.el.current.style.setProperty('--end-top', `${followEndPosition / 10}rem` )

        } // setFollowVariables

        let setFollowVariablesDebounce = Debounce(function () {
            setFollowVariables();
        }, 250);


        
        // ! GSAP

        const ctx = gsap.context(() => {

            setFollowVariables();
            window.addEventListener('resize', setFollowVariablesDebounce);


            // ! SWITCH POSITION

            function switchPosition($el, direction){
                $follow.el.current.classList.remove('has-changed')
                
                let dummy = $follow.el.current.offsetHeight

                let prevPosition = $follow.el.current.dataset.position;
                
                switch ($el.dataset[direction]) {
                    case 'left':
                        $follow.el.current.dataset.position = 'left'
                        break
                    case 'right':
                        $follow.el.current.dataset.position = 'right'
                        break
                    case 'center':
                        $follow.el.current.dataset.position = 'center'
                        break
                    case 'home-center':
                        $follow.el.current.dataset.position = 'home-center'
                        break
                }
                
                if( $follow.el.current.dataset.position !== prevPosition ){
                    // $follow.el.style.opacity = 0;
                    $follow.el.current.classList.add('has-changed')
                }

            } // switchPosition


            // * Home Hero integration
            let hasHomeHero = homeHero ? true : false
            let $homeHeroIso = hasHomeHero ? homeHero.hero.iso.current : null;


            // ! MATCHMEDIA

            const followMatchMedia = gsap.matchMedia()
            followMatchMedia.add({
                isDesktop: '(min-width: 61.25rem)',
                noMotion: '(prefers-reduced-motion)'
            }, (context) => {
                let {isDesktop, noMotion} = context.conditions

                if( !isDesktop ) return; // Disable animations if not Desktop

                // * VISIBLE 

                ScrollTrigger.create({
                    trigger: $follow.triggers.visible.current,
                    start: `center center`,
                    end: `center center`,
                    // markers: true,
                    onEnter: () => {

                        // console.log('%cDown | conEnter', 'color:limegreen')

                        if( hasHomeHero ){

                            $homeHeroIso.style.opacity = 0;
                            $follow.el.current.classList.add('is-visible', 'override')
                            
                        } else {

                            $follow.el.current.classList.add('is-visible')

                        }
                        
                        ScrollTrigger.refresh()
                        return switchPosition($follow.triggers.visible.current, 'position')

                    },
                    onEnterBack: () => {

                        if( hasHomeHero ){
                            $homeHeroIso.style.opacity = 1;
                            homeHero.heroTls.hide.play(0)
                        }
                        
                        $follow.el.current.classList.remove('is-visible')
                        return switchPosition($follow.triggers.visible.current, 'position')

                    },
                })

                // * FIXED 

                ScrollTrigger.create({
                    trigger: $follow.el.current,
                    start: `center center`,
                    end: `center center`,
                    // markers: true,
                    onEnter: () => {
                        
                        $follow.el.current.classList.remove('has-changed')
                        $follow.el.current.classList.add('is-fixed')

                    },
                    onEnterBack: () => {
                        
                        $follow.el.current.classList.remove('has-changed')
                        $follow.el.current.classList.remove('is-fixed')

                    },
                })

                // * MOVERS
                
                for (const [$mover] of $follow.triggers.mover.current.entries()) {

                    ScrollTrigger.create({
                        trigger: $mover,
                        start: `top center`,
                        end: `bottom center`,
                        // markers: debug,
                        onEnter: () => {
                            // console.log(`%conEnter | Actual: ${$mover.dataset.position}`, 'color: red')
                            return switchPosition($mover, 'position')
                        },
                        onEnterBack: () => {
                            // console.log(`%conEnterBack | Actual: ${$mover.dataset.position}`, 'color: lightblue')
                            return switchPosition($mover, 'position')
                        },
                        onLeave: () => {
                            // console.log(`%conLeave | Next: ${$mover.dataset.nextPosition}`, 'color: limegreen')
                            return switchPosition($mover, 'nextPosition')
                        },
                        onLeaveBack: () => {
                            // console.log(`%conLeaveBack | Prev: ${$mover.dataset.prevPosition}`, 'color: yellow')
                            switchPosition($mover, 'prevPosition')
                        },
                    })

                } // For Movers Map

                // * COLORS
                
                for (const [$color] of $follow.triggers.color.current.entries()) {

                    ScrollTrigger.create({
                        trigger: $color,
                        start: `top center`,
                        end: `bottom center`,
                        // markers: debug,
                        onEnter: () => {
                            // console.log(`%conEnter | Color switch: ${$color.dataset.color}`, 'color: red')
                            return $follow.el.current.dataset.color = $color.dataset.color
                        },
                        onEnterBack: () => {
                            // console.log(`%conEnterBack | Color switch: ${$color.dataset.color}`, 'color: lightblue')
                            return $follow.el.current.dataset.color = $color.dataset.color
                        },
                        onLeave: () => {
                            // console.log(`%conLeave | Color switch: ${$color.dataset.nextColor}`, 'color: limegreen')
                            return $follow.el.current.dataset.color = $color.dataset.nextColor
                        },
                        onLeaveBack: () => {
                            // console.log(`%conLeaveBack | Color switch: ${$color.dataset.prevColor}`, 'color: yellow')
                            return $follow.el.current.dataset.color = $color.dataset.prevColor
                        },
                    })

                } // For Color Map


                // * END 

                ScrollTrigger.create({
                    trigger: $follow.triggers.end.current,
                    start: `top center`,
                    end: `bottom center`,
                    // markers: true,
                    onEnter: () => {
                        
                        $follow.el.current.classList.add('is-finished')
                        setFollowVariables();

                    },
                    onEnterBack: () => {
                        
                        $follow.el.current.classList.remove('is-finished')

                    },
                })


            }) // microInteractionsMatchMedia add
        }); // <- IMPORTANT! Scopes selector text
        
        return () => ctx.revert(); // cleanup

    }, []) // UseLayoutEffect
} // followInit