export const dateTransform = (unformattedDate, lang) => {

    let splittedDate = unformattedDate.split('-');

    let monthTranslated = monthTranslation(splittedDate[1], lang);
    
    let retDate = `${lang == "en" ? `${monthTranslated} ${splittedDate[2]}` : `${splittedDate[2]} ${monthTranslated}`}, ${splittedDate[0]}`

    return retDate;
    
}//dateTransform


const monthTranslation = (month, lang) => {

    let selectedMonth = monthArray[lang][month - 1]

    return selectedMonth;
    
}//monthTranslation


const monthArray = {

    en: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ],
    es: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
    ],
    pt: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ],
      
}
