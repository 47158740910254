import React from "react"
// import * as styles from '../../styles/components/btn.module.css'

export default function Closing({ heading, text, cta, ctaUrl, ctaText }) {
    
  return (
    <>

        <div className="c-closing c-closing--home js-transition js-transition--parent">
            <div className="c-closing__deco c-closing-deco js-transition js-transition--parent">
                <div className="c-closing-deco__col c-closing-deco__col--1" />
                <div className="c-closing-deco__col c-closing-deco__col--2" />
                <div className="c-closing-deco__col c-closing-deco__col--3" />
            </div>
            <div className="c-closing__wrapper o-wrapper u-text">

                {heading && <p className="c-closing__heading c-heading js-transition__child">
                    {heading}
                </p>}
                {text &&<p className="c-closing__text u-text js-transition__child">
                    {text}
                </p>}

                {cta && <a href={ctaUrl} target="_blank" rel="noopener" className="c-closing__link c-btn c-btn--alt-hover js-transition__child">{ctaText}</a>}
                    
            </div>
        </div>


    </>
  )
}



