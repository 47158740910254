import { useLayoutEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

export default function MicroInteractionsInit(transitionsMap) {

    // console.log('%c MICRO INTERACTIONS: function', 'color:blue;font-size:20px;padding:1em;border:2px solid currentColor')

    useLayoutEffect(() => {

        const ctx = gsap.context(() => {

            if (window.matchMedia('(prefers-reduced-motion)').matches) return; // Disable animations if Prefered reduced motion is activated

            if (transitionsMap.length <= 0) return; // Don't execute if no transitions available

            const microInteractionsMatchMedia = gsap.matchMedia()
            microInteractionsMatchMedia.add({
                isMobile: '(max-width: 47.9375rem)',
                isTablet: '(min-width: 48rem)',
                isDesktop: '(min-width: 61.25rem)',
                isDesktop2: '(min-width: 75rem)',
                isHd: '(min-width: 87.5rem)',
                isFullHd: '(min-width: 100rem)',
                noMotion: '(prefers-reduced-motion)'
            }, (context) => {
                let { isMobile, isTablet, isDesktop, isDesktop2, isHd, isFullHd, noMotion } = context.conditions

                if (noMotion) return; // Disable animations if Prefered reduced motion is activated

                // ! TRANSITIONS START

                let i = 0;
                for (const [$el] of transitionsMap.current.entries()) {

                    // * DOWN SCROLLING

                    function downScrollTrigger($el, className, i) {

                        // Get custom property
                        let customDownStartOffset = window.getComputedStyle($el).getPropertyValue('--anim-down-start-offset')
                        // If first character is a space, remove it
                        if (customDownStartOffset.charAt(0) === ' ') customDownStartOffset = customDownStartOffset.split(' ')[1]
                        // If custom property is not available, set default
                        let downStartOffset = `top ${customDownStartOffset ? customDownStartOffset : '75%'}`


                        let debugDownScrolling = false;

                        // if ( i === transitionsMap.length - 1)
                        // if ( i === 1)
                        //     debugDownScrolling = true;

                        let st = ScrollTrigger.create({
                            trigger: $el,
                            start: downStartOffset,
                            end: `-10% bottom`,
                            markers: debugDownScrolling ? true : false,
                            onEnter: () => {
                                if (debugDownScrolling) console.log('%cDown | conEnter', 'color:limegreen')
                                $el.classList.add(className)
                            }
                        });

                        return st
                    } // downScrollTrigger

                    // * DISABLE ANIMATION ON SCROLL UP

                    function upScrollTrigger($el, className, i) {

                        // Get custom property
                        let customUpStartOffset = window.getComputedStyle($el).getPropertyValue('--anim-up-start-offset')
                        // If first character is a space, remove it
                        if (customUpStartOffset.charAt(0) === ' ') customUpStartOffset = customUpStartOffset.split(' ')[1]
                        // If custom property is not available, set default
                        let upStartOffset = `${customUpStartOffset ? customUpStartOffset : 'top'} bottom`


                        let debugUpScrolling = false;
                        // if ( i === transitionsMap.length - 1)
                        // if ( i === 1)
                        //     debugUpScrolling = true;

                        let st = ScrollTrigger.create({
                            trigger: $el,
                            start: upStartOffset,
                            end: `bottom bottom`,
                            markers: debugUpScrolling ? true : false,
                            onLeaveBack: () => {
                                if (debugUpScrolling) console.log('%cUp | onLeaveBack', 'color:#00bbff')
                                $el.classList.remove(className)
                            },
                        })

                        return st
                    } // upScrollTrigger


                    let className = (!$el.classList.contains('js-transition--mobile')) ? 'is-animated' : 'is-animated-xs'

                    downScrollTrigger($el, className, i);
                    upScrollTrigger($el, className, i);


                    i++;
                }

                // ! TRANSITIONS END

            }) // microInteractionsMatchMedia add

        }); // <- IMPORTANT! Scopes selector text

        /*
        console.log(
            `%cScrollTriggers Count - End: ${ScrollTrigger.getAll().length}`,
            'color:blue;font-weight:bold;font-size:15px;padding:1em',
        )
        setTimeout(()=>{
            ScrollTrigger.refresh()
            console.log(
                `%cScrollTrigger Refresh Timeout`,
                'color:white;background-color:red;font-weight:bold;font-size:15px;padding:1em',
            )
        }, 1000)
        */
        // TODO Hacer que funcione el refresh en cada pageRoute (PARA GATSBY LINK)

        return () => ctx.revert(); // cleanup

    }, []) // UseLayoutEffect
} // MicroInteractionsInit